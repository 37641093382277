html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

body {
    font-family: "JetBrains Mono";
    margin: 0;
    padding: 0;
    color: #D8DBE2;
    background-color: #1c1c22 ;
  }
  
  nav ul {
    display: flex;
    gap: 1rem;
    list-style: none;
  }

  html {
    scroll-behavior: smooth;
    /* overflow: -moz-scrollbars-vertical;  */
    overflow-y: scroll;
  }

  /* body::before {
    content: '';
    position: fixed;
    top: 20%;
    bottom: 20%;
    left: 0;
    right: 0;
    background-color: rgba(88, 164, 176, 0.1); 
    pointer-events: none;
    z-index: 1000;
  } */
